:root {
    --primary-main-color: #091747;
    --primary-light-color: #303773;
    --secondary-main-color: #F66F81;
    --info-main-color: #00BAB6;
    --info-light-color: #DAF0F2;
    --light-main-color: #b4b4b4;
    --light-light-color: #C4C4C4;
    --warning-main-color: #f57742;
    --success-main-color: #6BCBA0;
    --danger-main-color: #DC3545;

    /* Font sizes */
    --font-size-base: 14px;
    --font-size-small: 0.875rem;
    --font-size-medium: 1rem;
    --font-size-large: 1.25rem;
    --font-size-xlarge: 1.5rem;
}