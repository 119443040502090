.helpercard-container {
    display: flex;
    flex-direction: column;
    gap: 26px;
    flex: 1;
}

.helpercard-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 24px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    background-color: white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    color: var(--primary-main-color) !important;
}

.helpercard-block.mounted {
    opacity: 1;
}

.helpercard-header {
    font-size: var(--font-size-base);
    color: var(--primary-main-color) !important;
    text-align: left;
    white-space: pre-line;
    font-family: "Poppins-bold", sans-serif;
}

.helpercard-text {
    font-size: 1rem;
    color: var(--primary-main-color) !important;
    width: 100%;
    text-align: left;
    white-space: pre-line;
}

/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    .helpercard-container,
    .helpercard-displayer-container {
        display: none !important;
    }
}