.card, .box-item {
    padding: 20px 20px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    width: 100%;
    flex: 1;
    background-color: white;
    box-sizing: border-box;
    overflow-y: auto;
}

.box-item {
    border: 1px solid #f2f2f2;
}

.card-noshadow {
    padding: 20px 20px;
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    border: none;
}

.transparent {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0 10px;
    border-radius: 0;
}

.tp0 {
    padding-top: 0;
}

.standardform {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.standardform-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
}

.custom-card {
    position: relative;
    background-color: #ECF4F4 !important;
}

.standardform-w70 {
    width: 70%;
}

.standardform-w55 {
    width: 55%;
}

.standardform-w50 {
    width: 50%;
}

.standardform-fixedheight-100 {
    height: 75vh;
}

.flex-center-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.flex-center-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 70%;
}

.w-100 {
    width: 100% !important;
    max-width: 100% !important;
}

.w-800 {
    width: 800px !important;
}

.w-580 {
    width: 580px !important;
}

.mb60 {
    margin-bottom: 60px;
}

.mb40 {
    margin-bottom: 40px;
}

.account-status-active {
    border-color: var(--info-main-color) !important;
}

.account-status-active .MuiChip-label {
    white-space: normal;
    font-weight: normal !important;
    color: var(--info-main-color) !important;
}

.account-status-inactive {
    border-color: #919191;
}

.account-status-inactive .MuiChip-label {
    white-space: normal;
    font-weight: normal !important;
    color: #919191 !important;
}

.tag-recommendation-level {
    height: auto !important;
    padding: 4px 0 !important;
}

.tag-recommendation-level .MuiChip-label {
    white-space: normal;
    padding: 0 8px;
    font-family: "Poppins-bold", sans-serif !important;
    color: #ffffff !important;
}

.recommandation-level-default {
    background-color: var(--primary-light-color) !important;
    font-weight: bold;
}

.recommandation-level-default .MuiChip-label {
    color: #ffffff !important;
    font-family: "Poppins-bold", sans-serif !important;
}

.recommandation-level-orange {
    background-color: #FD6939 !important;
    color: #ffffff !important;
    font-weight: bold;
}

.recommandation-level-yellow {
    background-color: #F4C741 !important;
    color: #ffffff !important;
    font-weight: bold;
}

.recommandation-level-green {
    background-color: var(--success-main-color) !important;
    color: #ffffff !important;
    font-weight: bold;
}

.recommandation-level-default .MuiChip-label,
.recommandation-level-orange .MuiChip-label,
.recommandation-level-yellow .MuiChip-label,
.recommandation-level-green .MuiChip-label {
    color: #ffffff !important;
    font-family: "Poppins-bold", sans-serif !important;
}

.tag-evaluation {
    background-color: #D9D9D9 !important;
    color: #000000 !important;
    padding: 0 5px !important;
    border-radius: 10px !important;
    height: 29px !important;
    min-width: 7rem;
}

.tag-mission,
.tag-quote,
.tag-contract,
.tag-invoice,
.tag-satisfaction {
    font-family: "Poppins-bold", sans-serif !important;
    text-align: center;
    height: auto !important;
    padding: 4px 0 !important;
}

.tag-mission .MuiChip-label,
.tag-quote .MuiChip-label, .tag-quote .MuiTypography-root,
.tag-contract .MuiChip-label, .tag-contract .MuiTypography-root,
.tag-invoice .MuiChip-label, .tag-invoice .MuiTypography-root,
.tag-satisfaction .MuiChip-label {
    font-family: "Poppins-bold", sans-serif !important;
    white-space: normal;
    color: #ffffff !important;
}

.tosign,
.open,
.freelance-signature,
.enterprise-signature,
.pending {
    background-color: #FD6939 !important;
}

.tag-mission.open {
    background-color: #C7B3E5 !important;
}

.tag-mission.in-progress {
    background-color: #67BE7A !important;
}

.canceled, .rejected, .not-specified, .tag-mission.archived, .tag-mission.deleted {
    background-color: #929390 !important;
}

.refused, .not-interested {
    background-color: var(--danger-main-color) !important;
}

.paid, .tag-mission.closed {
    background-color: #016276 !important;
}

.invalid {
    background-color: #a3e571 !important;
}

.validated, .signed, .interested {
    background-color: var(--success-main-color) !important;
}


.d-none {
    display: none;
}

.custom-property {
    margin: 0;
    width: fit-content;
}

.align-item-center {
    align-items: center;
}

.mb-5 {
    margin-bottom: 10px;
}

.container-tag {
    display: flex;
    flex-wrap: wrap; /* Permet à la flexbox de passer à la ligne si nécessaire */
}

.item-tag {
    flex: 1;
    min-width: calc(50% - 10px);
    margin: 5px;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
}

.mandatory-legend {
    color: #f56f80;
    text-align: right;
    font-style: italic;
}

.checkbox-invoice.form-check input:checked {
    background-color: #f56f80;
    border-color: #f56f80;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}

.modal-content {
    padding: 15px;
}

.box-pdf-viewer {
    width: 550px;
    height: 250px;
}

.MuiDateCalendar-root.margin-0 {
    margin: 0 !important;
    max-height: 310px !important;
    margin-top: -18px !important;
    margin-left: -24px !important;
    margin-right: -24px !important;
}

.MuiDateCalendar-root .MuiPickersDay-root:focus:not(.Mui-selected) {
    background-color: transparent !important;
}

.MuiToggleButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.54) !important;
}

.MuiRating-root label {
    font-size: 1.5rem !important;
    color: #faaf00 !important;
    cursor: pointer !important;
    text-align: left !important;
}

/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    .standardform {
        width: 100% !important;
    }

    .card {
        flex: auto !important;
        height: auto !important;
    }

    .standardform-card {
        gap: 15px;
    }

    .card.transparent {
        padding: 20px 0;
    }

    .standardform-fixedheight-100 {
        height: 60vh;
    }
}
