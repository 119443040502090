@font-face {
    font-family: "Poppins";
    src: url("assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-bold";
    src: url("assets/fonts/Poppins-Bold.ttf") format("truetype");
}

.App {
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: var(--primary-main-color);
    align-items: flex-start;
    justify-content: center;
    font-size: var(--font-size-base);
}

.d-none {
    display: none;
}

.modal-header {
    justify-content: center !important;
}

.modal-footer {
    justify-content: space-between !important;
}

html {
    font-size: var(--font-size-base);
}

.pdf-viewer-container .rpv-core__viewer {
    box-shadow: none; /* Remove box shadow */
}

a {
    color: var(--primary-main-color) !important;
}

ul.general-ul {
    margin-bottom: 0;
}

ul.general-ul li {
    margin-bottom: 8px;
}

ul.general-ul li:last-child {
    margin-bottom: 0 !important;
}

/* Alert */
.MuiAlert-root {
    align-items: center;
    border-radius: 10px !important;
    line-height: 1.2 !important;
}

.MuiAlert-root.MuiAlert-outlined {
    line-height: 1 !important;
}

.MuiAlert-root.MuiAlert-standardInfo {
    background-color: #EBEBEB;
    color: #919191;
}

.MuiAlert-root.MuiAlert-filled {
    color: #ffffff;
}

.MuiAlert-root.MuiAlert-filledWarning {
    background-color: #F4C741 !important;
    color: var(--primary-main-color);
}

.MuiAlert-root .MuiAlert-icon {
    padding: 0;
}

.MuiAlert-root .MuiAlert-action {
    padding-top: 0 !important;
}

.MuiListItemIcon-root {
    min-width: 23px !important;
}

.Mui-textTruncate {
    display: block !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.MuiTypography-body1 {
    color: var(--primary-main-color);
    text-align: left;
    max-width: 100%;
    white-space: pre-line;
}

.MuiTypography-h1 {
    font-size: 20px !important;
    font-weight: 700 !important;
    font-family: "Poppins-bold", sans-serif !important;
}

.MuiTypography-h2 {
    font-size: 18px !important;
    font-weight: 700 !important;
    font-family: "Poppins-bold", sans-serif !important;
}

.MuiTypography-h3 {
    font-size: var(--font-size-base) !important;
    font-weight: 700 !important;
    font-family: "Poppins-bold", sans-serif !important;
}

.MuiTypography-body1 .MuiLink-root {
    color: var(--primary-main-color) !important;
}

.MuiMenuItem-root .MuiTypography-root {
    cursor: pointer;
}

.MuiChip-labelMedium, .MuiChip-labelSmall {
    color: var(--primary-main-color) !important;
    font-size: var(--font-size-base) !important;
}

.profile-title .MuiTypography-label {
    color: var(--primary-main-color) !important;
    font-size: 18px !important;
    font-family: "Poppins-bold", sans-serif;
}

.MuiMenu-paper {
    color: var(--primary-main-color) !important;
}

.MuiTooltip-tooltip {
    font-size: var(--font-size-base) !important;
    /*background-color: red !important;*/
}

.MuiTooltip-arrow {
    /*color: red !important;*/
}

.MuiTooltip-light .MuiTooltip-tooltip {
    background-color: #ffffff !important;
    color: var(--primary-main-color) !important;
    border-radius: 10px !important;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .12) !important;
}

.CustomTooltipCell .MuiTooltip-tooltip {
    padding: 15px !important;
}

.status-new, .status-in-progress {
    color: var(--light-light-color) !important;
}

.status-published {
    color: var(--success-main-color) !important;
}

.status-closed {
    color: var(--danger-main-color) !important;
}

/* Styles pour les écrans très petits (sm) */
/* Styles pour les écrans moyens (md) */
@media (max-width: 600px), (min-width: 601px) and (max-width: 1023px) {
    .MuiPaper-root:not(.MuiDialog-paper) {
        max-height: 100% !important;
    }

    .MuiFormControl-root {
        width: 100% !important;
    }

    .MuiMenu-paper {
        width: 100%;
        margin-top: 4px;
    }

    .MuiStepButton-root.MuiStepButton-horizontal {
        padding: 10px 10px;
    }
}