.react-calendar {
    border: none !important;
    font-family: "Poppins", sans-serif !important;
    width: 400px !important;
}

.react-calendar__month-view__days {
    gap: 15px;
    display: flex;
    flex-wrap: wrap;
}

.react-calendar__tile {
    position: relative;
    left: 8px;
    display: flex !important;
    align-items: center !important;
    flex: none !important;
    justify-content: center !important;
    font-weight: 400 !important;
    font-size: 0.85rem !important;
    color: rgba(0, 0, 0, 0.87) !important;
    width: 42px !important;
    height: 42px !important;
    border-radius: 50% !important;
    padding: 0 !important;
    margin: 0 !important;
}

.react-calendar__tile .tile-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-calendar__tile:disabled {
    background-color: #ffffff !important;
}

.react-calendar button.react-calendar__tile abbr {
    display: none !important;
}

button.react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0 !important;
    cursor: default !important;
}

.react-calendar__navigation__label {
    pointer-events: none;
}

.react-calendar__navigation__label__labelText {
    font-size: 1rem !important;
    color: #212529 !important;
    text-transform: capitalize;
}

.react-calendar__navigation__arrow {
    font-size: 1.3rem !important;
    color: #212529 !important;
}

.react-calendar__navigation button:enabled {
    background-color: #ffffff !important;
}

.react-calendar__navigation button:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.react-calendar__tile--now {
    background-color: #eff2f7 !important;
}

.react-calendar__tile--active {
    background-color: #ffffff !important;
}

.highlight-selecting {
    background-color: #eff2f7 !important;
}

button.react-calendar__month-view__days__day.highlight-available {
    background-color: var(--success-main-color) !important;
    color: #ffffff !important;
}

button.react-calendar__month-view__days__day.highlight-partially-available {
    background-color: var(--warning-main-color) !important;
    color: #ffffff !important;
}

button.react-calendar__month-view__days__day.highlight-not-available {
    background-color: var(--danger-main-color) !important;
    color: #ffffff !important;
}

.react-calendar__tile:enabled:hover {
    background-color: #e6e6e6 !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

.status-available {
    color: var(--success-main-color) !important;
}

.status-partially-available {
    color: var(--warning-main-color) !important;
}

.status-not-available {
    color: var(--danger-main-color) !important;
}