.w-45 {
    width: 45%;
}

.w-48 {
    width: 48%;
}

.two-step {
    width: 300px !important;
    margin: auto !important;
    margin-bottom: 100px !important;
}

.icon {
    margin: 10px 10px;
}

.MuiPaper-root:not(.MuiDialog-paper):not(.MuiCard-root) {
    max-height: calc(100% - 300px);
}

.MuiFormLabel-root {
    font-size: 1rem !important;
}

.MuiFormLabel-asterisk, .mandatory-text {
    color: #f56f80;
}

.MuiSelect-select {
    text-align: left;
}


/* Checkbox */
.MuiCheckbox-root {
    padding: 0 9px !important;
}

.MuiCheckbox-root:hover {
    background-color: transparent !important;
}

.MuiCheckbox-root .MuiTouchRipple-root {
    opacity: 0 !important;
}

/* Input */
.MuiInputBase-root {
    background-color: #ffffff;
}

.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 12px 14px;
    font-size: var(--font-size-base);
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline {
    padding: 0;
}

.MuiInputLabel-root.MuiInputLabel-outlined {
    transform: translate(14px, 11px) scale(1);
}

.MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(14px, -9px) scale(0.75)
}

/* Select */
.MuiInputBase-input.MuiAutocomplete-input {
    font-size: 15px;
}

.MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
    padding: 4.5px 10px !important;
}