body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Poppins", sans-serif;
    font-size: var(--font-size-base);
}

.Mui-icon {
    color: var(--primary-main-color) !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

td {
    border-style: hidden;
}

.table > :not(caption) > * > * {
    background-color: initial;
}

.text-normal {
    font-size: 15px;
    font-weight: 800;
    color: var(--primary-light-color);
}

::-webkit-scrollbar {
    width: 7px;
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: #e1e3ea; /* Couleur de la thumb (la poignée) */
    border-radius: 6px; /* Arrondir les coins de la thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #e1e3ea; /* Couleur de la thumb au survol */
}

::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Couleur de la piste */
}

.pl-pr-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.pl-pr-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.MuiSkeleton-root {
    transform: none !important;
}

.mr-5 {
    margin-right: 5px;
}

.mt-2 {
    margin-top: 2px;
}

.mt-5 {
    margin-top: 5px;
}

.mb-2 {
    margin-bottom: 2px;
}

.mb-3 {
    margin-bottom: 2px;
}

.modal-dialog {
    font-family: "Poppins", sans-serif;
}

.text-normal {
    margin-left: 0;
    font-weight: 100;
    font-size: var(--font-size-base);
    color: var(--primary-light-color);
}

.text-invisible {
    opacity: 0;
}

.MuiDialog-paper {
    padding: 10px;
    max-width: 95% !important;
}

.fsize-12 {
    font-size: 12px !important;
}

.text-center-p10 {
    text-align: center;
    margin-top: 10px;
}

.small {
    font-size: 0.8rem !important;
}

.text-muted {
    color: #a19f9f !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.font-italic {
    font-style: italic !important;
}

.h-100 {
    height: 100% !important;
}

.justify-content-center {
    justify-content: center !important;
}