@import "~@fortawesome/fontawesome-free/css/all.min.css";

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    animation: spin 1s linear infinite;
}

.file-upload-container {
    border: 1px dashed #09174736;
    border-radius: 1ch;
}

.file-upload-container.error {
    border-color: #d32f2f !important;
    color: #d32f2f !important;
}

.file-upload-container.error .MuiTypography-label {
    color: #d32f2f !important;
}

.file-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff36;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 20px 15px;
    text-align: center;
}

.file-inputindex {
    width: 100%;
}

.file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.file-label {
    font-size: small;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.text-wrap {
    white-space: pre-wrap;
}
