.fas {
    padding: 10px 13px;
    cursor: pointer;
}

.input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.input-column {
    display: flex;
    flex-direction: column;
}

.input-column-standard {
    width: calc(100% - 240px);
}

.input-column-index {
    width: 100%;
}

.action-indication {
    font-size: 10px;
    margin: 0;
}

input[type="checkbox"] {
    height: 25px !important;
    width: 25px;
}

/* Style pour le groupe de formulaire */
.form-group {
    display: flex; /* Utiliser un affichage flexbox */
    align-items: center; /* Aligner verticalement le contenu au centre */
}

/* Style pour le label */
label {
    flex: 1;
}

/* Style pour le champ de saisie (input) */
.input-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

/* Style pour l'input (ou textarea) */
input[type="text"]:not(.MuiInputBase-input),
input[type="email"]:not(.MuiInputBase-input),
input[type="password"]:not(.MuiInputBase-input),
input[type="file"]:not(.MuiInputBase-input),
input[type="date"]:not(.MuiInputBase-input),
input[type="number"]:not(.MuiInputBase-input),
input[type="url"]:not(.MuiInputBase-input),
textarea:not(.MuiInputBase-input) {
    font-weight: 500;
    font-family: Poppins;
    font-size: 11px;
    color: var(--primary-main-color);
    border-width: 0.1ch;
    border-radius: 1ch;
    border-color: #09174736;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="file"]::placeholder,
input[type="date"]::placeholder,
input[type="number"]::placeholder,
input[type="url"]::placeholder {
    color: #7a7a7a;
}

.input-mission-hw45 {
    width: calc(45% + 0.45 * 240px);
}

textarea {
    width: calc(26% + 0.45 * 240px);
}

input[type="text"]:not(.MuiInputBase-input),
input[type="email"]:not(.MuiInputBase-input),
input[type="password"]:not(.MuiInputBase-input),
input[type="number"]:not(.MuiInputBase-input),
input[type="url"]:not(.MuiInputBase-input),
input[type="date"]:not(.MuiInputBase-input) {
    height: 30px;
    padding: 7px 8px;
}

.field input[type="text"],
.field input[type="email"],
.field input[type="password"],
.field input[type="date"] {
    width: 100%;
    height: 40px;
}

.fieldholder input {
    width: 45%;
    display: flex;
}

.select-height {
    height: 30px !important;
}

.input-dropdown {
    height: 30px;
}

textarea:focus,
input:focus,
.input:focus {
    outline: 1.6px solid var(--primary-light-color); /* Remplacez "red" par la couleur de votre choix */
}
