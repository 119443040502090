.MuiTimeRange-item {
    border-radius: 4px !important;
    font-size: 0.875rem !important;
    padding: 6px 8px !important;
}

.MuiTimeRange-item.MuiButton-containedLight {
    background-color: #e9ecef !important;
    color: var(--primary-main-color) !important;
}

.MuiTimeRange-item.MuiButton-containedSuccess {
    background-color: var(--success-main-color) !important;
    color: #ffffff !important;
}