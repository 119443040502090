.option-toggle .MuiTypography-root {
    font-weight: bold;
}

.option-toggle .MuiToggleButton-root,
.option-toggle .MuiToggleButton-root:hover {
    padding: 3px 11px !important;
    background-color: #E2E0E8;
    color: var(--primary-main-color);
    border: none !important;
    text-transform: capitalize;
}

.option-toggle .MuiToggleButton-root.Mui-selected {
    background-color: var(--primary-main-color) !important;
    color: #ffffff !important;
}