.pdf-container-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 100%;
}

.pdf-container-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.pdf-worker-container-scroll {
    overflow: scroll;
    width: 100%;
}

.pdf-worker-container {
}

.pdf-controls-container {
    padding: 0px 10px;
}

