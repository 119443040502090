.ql-wysiwyg {
    width: 100% !important;
}

.ql-wysiwyg ol, .ql-wysiwyg ul {
    margin-bottom: 0 !important;
}

.ql-align-center {
    text-align: center !important;
}

.ql-align-right {
    text-align: right !important;
}

.ql-align-justify {
    text-align: justify !important;
}

