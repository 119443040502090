.public-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.public-layout-header {
    background-color: var(--info-light-color);
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 20vh;
}

.public-layout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.public-layout .pagetitle {
    margin-bottom: 0;
}

.public-layout .offer-detail .MuiChip-root.MuiChip-outlined {
    justify-content: start;
    border-radius: 5px;
    min-width: 150px;
    padding-right: 15px;
}

.public-layout .offer-detail .MuiChip-outlined .MuiChip-label {
    color: var(--info-main-color) !important;
    font-family: "Poppins-bold", sans-serif !important;
}

.offer-detail .MuiButton-square {
    padding: 10px;
}
