.sectionlist {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.section-item {
    box-sizing: border-box;
    cursor: pointer;
    text-align: left;
    font-size: var(--font-size-base);
    border-radius: 10px;
    border: none;
    padding: 1em 10%;
    width: 100%;
    display: inline-block;
    line-height: 1;
    transition: background-color 0.3s ease;
}

.section-item.section-item-primary {
    background-color: #09174720;
    color: var(--primary-main-color);
    font-family: "Poppins-bold", sans-serif !important;
}

.section-item.section-item-primary:hover {
    background-color: var(--primary-main-color);
    color: #ffffff;
}

.section-item.section-item-primary.active {
    background-color: var(--primary-main-color);
    color: #ffffff;
}

.section-item.section-item-info {
    background-color: #d9f3f3;
    color: var(--info-main-color);
    font-family: "Poppins-bold", sans-serif !important;
}

.section-item.section-item-info:hover {
    background-color: var(--info-main-color);
    color: #ffffff;
}

.section-item.section-item-info.active {
    background-color: var(--info-main-color);
    color: #ffffff;
}

.section-item-icon {
    transform: scale(2);
}