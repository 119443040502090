.onboarding-row-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.onboarding-column-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    overflow: hidden scroll;
}

.onboarding-column-left {
    box-sizing: border-box;
    width: 20%;
    height: 100%;
    padding: 5% 2% 2% 2%;
    justify-content: space-between;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.onboarding-column-right {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 80%;
    padding: 3% 3% 2% 3%;
    justify-content: flex-start;
    overflow: hidden scroll;
}

.onboarding-row-container .app-logo {
    width: 90px;
    border-radius: 50%;
    height: auto;
    aspect-ratio: 1;
    cursor: pointer;
}

.onboarding-card-w100 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.onboarding-welcome-message {
    font-family: "Poppins-bold", sans-serif;
    color: var(--primary-light-color);
}

.public-padding-20p {
    padding: 0 20%;
}

/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    .onboarding-row-container {
        flex-direction: column;
    }

    .onboarding-column-right {
        width: 100%;
    }

    .onboarding-row-container .app-logo {
        width: 60px;
    }
}