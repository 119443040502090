.box-file {
    border: 1px solid #09174736;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
}

.box-file.error {
    border-color: #d32f2f !important;
    color: #d32f2f !important;
}

.box-file.error .MuiTypography-root {
    color: #d32f2f !important;
}

.box-file .MuiTypography-root {
    font-size: 12px;
}

.file-image, .file-image-skeleton {
    width: 150px !important;
    height: 150px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    object-fit: contain !important;
    border-radius: 8px !important;
}

.profile-picture .box-file {
    border-radius: 50% !important;
    width: 100px !important;
    height: 100px !important;
}

.profile-picture .file-image,
.profile-picture .file-image-skeleton {
    width: 100px !important;
    height: 100px !important;
    border-radius: 50% !important;
    object-fit: cover !important;
}

/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    .box-file {
        width: 100px;
        height: 100px;
    }

    .box-file .MuiTypography-root {
        font-size: 11px;
    }

    .file-image {
        width: 70px;
        height: 70px;
    }
}