.freelanceinfostep-information-text{
    font-weight: 500;
    text-align: center;
    font-size: 12px;
    font-family: "Poppins-bold";
    color: var(--primary-main-color);
}

.helpercard-container-popup{
    display: flex;
    flex-direction: column;
    gap: 26px;
    flex: 1;
    width: 70%;
}

.margin-auto{
    margin:auto!important;
}