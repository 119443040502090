.custom-tags-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px !important;
    gap: 4px;
    width: 100%;
    min-height: 51px;
    max-height: 100%;
    overflow: hidden;
    cursor: pointer;
}

.tag-item {
    font-size: var(--font-size-base) !important;
    text-align: center;
    height: auto !important;
    padding: 2px 0 !important;
    width: auto !important;
    color: var(--primary-main-color) !important;
}

.MuiPopover-paper {
    border-radius: 10px !important;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .12) !important;
}

.MuiPopover-paper .custom-tags-container {
    padding: 8px !important;
    gap: 5px;
}

.MuiPopover-paper .MuiChip-label {
    white-space: normal !important;
}