.reporting-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 10px 15px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
}

.reporting-card .title {
    text-align: center;
    font-size: 12px;
    color: #919191;
}

.reporting-content {
    width: 100% !important;
    height: 100%;
    max-height: 320px;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}

.reporting-card .MuiTypography-label {
    font-size: 16px;
}

#expenseBoard .btn-navigation {
    color: var(--primary-main-color);
    padding: 8px 6px !important;
}

#expenseBoard .btn-current-month {
    color: var(--primary-main-color);
    padding: 8px 25px !important;
}

#freelanceBoard .MuiPaper-root,
#expenseBoard .MuiPaper-root {
    max-height: 100% !important;
}