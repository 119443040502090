.MuiTabs-root {
    min-height: 35px !important;
}

.MuiTabs-root .MuiTab-root.MuiButtonBase-root  {
    text-transform: initial;
    min-height: 35px;
    font-size: var(--font-size-base);
    color: var(--primary-main-color);
    opacity: 1;
}

.MuiTabs-root .MuiTabs-indicator {
    background-color: var(--info-main-color);
}
