.freelance-profile-card {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 3px 4px 0 #00000040;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: start;
    color: var(--primary-main-color);
    position: relative;
}

.freelance-profile-card .MuiCard-root {
    box-shadow: none;
    border-radius: 5px;
}

.freelance-profile-card .user-card-image {
    width: 80px !important;
    height: 80px !important;
    border: 4px solid #ffffff;
    position: absolute;
    top: -48px;
}

.freelance-profile-card .available .user-card-image {
    border-color: var(--success-main-color);
}

.freelance-profile-card .partially-available .user-card-image {
    border-color: var(--warning-main-color);
}

.freelance-profile-card .not-available .user-card-image {
    border-color: var(--danger-main-color);
}

.freelance-profile-card .freelance-availability-display {
    flex-direction: row !important;
    flex-wrap: wrap;
    text-align: left;
    width: 100%;
    font-size: 12px;
    line-height: 1.2;
    gap: 3px;
}

.freelance-profile-card .freelance-availability-date {
    text-transform: lowercase;
}

.freelance-profile-card .custom-tags-container {
    padding: 0 !important;
}

.MuiCardContent-root {
    padding: 12px 16px 16px 16px !important;
}

.MuiCardMedia-root .file-image {
    display: block !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
    object-fit: cover !important;
}