.contentholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0;
    color: var(--primary-light-color) !important;
}

.contentholder .logosmall {
    width: 90px;
    border-radius: 50%;
    height: auto;
    aspect-ratio: 1;
}

.logomedium {
    width: 100px;
    height: auto;
    aspect-ratio: 1;
}

.contentholder h1 {
    text-align: center;
    font-size: 23px;
    font-family: "Poppins-bold", sans-serif;
    color: var(--primary-light-color) !important;
    margin-top: 20px;
}

.contentholder h3, .fw-bold {
    font-family: "Poppins-bold", sans-serif;
}

.modal-header {
    border-bottom: 0 !important;
}

.modal-footer {
    border-top: 0 !important;
}


.v-hidden {
    visibility: hidden;
}

.h-550 {
    height: 550px;
}

.cursor-pointer {
    cursor: pointer;
}

.text-align-end {
    text-align: end;
}

.text-align-left {
    text-align: left;
}

.fsize-14 {
    font-size: var(--font-size-base) !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.mt-20 {
    margin-top: 20px;
}

.mt-10 {
    margin-top: 10px;
}

.div-text-left {
    text-align: left;
    width: 100%;
    padding: 10px;
}

.w-350 {
    width: 350px !important;
}

.h-250 {
    height: 250px !important;
}

.active-section {
    cursor: pointer;
}

/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    h1 {
        font-size: 28px;
    }

    .contentholder {
        margin: 30px 0;
    }

    .centerformcard {
        width: 90% !important;
    }
}
