.bloc-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.conteneur-select {
    display: flex;
    justify-content: center;
    align-items: center;
}

.option-select {
    text-align: center;
    border: 2px solid #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 7px;
    color: var(--primary-light-color);
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    height: 120px;
}

.selected {
    background-color: var(--info-main-color);
    color: white;
}

.d-none {
    display: none;
}

/*For diagonal place*/
.box-superpose {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 4px 0;
}

.svg-container {
    position: relative;
    display: flex;
}

.svg-icon {
    width: 100%;
}

.second-svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateX(50%);
}

.text-title-div {
    text-align: center;
    font-weight: 800;
    color: var(--primary-light-color);
}

.text-title-not-align {
    font-weight: 800;
    color: var(--primary-light-color);
}

.bloc-list-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: var(--primary-light-color);
    padding: 5px;
    cursor: pointer;
}

.btn-step {
    margin: 20px 0 10px 0;
}

.text-wrap-70 {
    width: 160px; /* Ajustez la largeur selon vos besoins */
    word-wrap: break-word;
}

.signature-canvas {
    border: 2px solid #ebebeb;
    border-radius: 5px;
    width: 100%;
    height: 250px;
}

.mission-format .MuiToggleButton-root,
.mission-format .MuiToggleButton-root:hover {
    line-height: 1.6;
    max-width: 195px;
    width: 195px;
    text-transform: initial;
    font-size: var(--font-size-base);
    font-weight: 400;
    padding: 4px 11px;
    background-color: #E2E0E8;
    color: var(--primary-main-color);
    border: none;
}

.mission-format .MuiToggleButton-root.Mui-selected {
    background-color: var(--primary-main-color) !important;
    color: #ffffff !important;
    font-weight: bold;
}

.mission-format .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type) {
    border-bottom: none;
    border-right: 2px solid var(--primary-main-color);
}


/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    .mission-section {
        width: 100%;
    }

    .mission-content {
        width: 100%;
    }

    .option-select {
        padding: 10px;
        height: 100px;
    }

    .conteneur-select {
        flex-wrap: wrap;
    }

    .mission-format .MuiToggleButton-root,
    .mission-format .MuiToggleButton-root:hover {
        width: 100%;
        max-width: 100%;
        padding: 10px;
    }

    .mission-format .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type) {
        border-right: none;
        border-bottom: 2px solid var(--primary-main-color);
    }
}