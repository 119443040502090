.quill, .ql-editor {
    background-color: #ffffff;
}

.ql-toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.ql-header.ql-picker {
    text-align: left;
}

.ql-container {
    min-height: 150px;
}

.quill.error .ql-toolbar,
.quill.error .ql-container {
    border-color: #d32f2f !important;
}