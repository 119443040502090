.kanban-pipeline-item {
    background-color: var(--info-light-color);
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    gap: 5px;
    transition: background-color 0.3s;
}

.kanban-pipeline-item:hover {
    filter: brightness(99%);
}

.kanban-pipeline-item .MuiOutlinedInput-notchedOutline {
    background-color: transparent;
}

.kanban-pipeline-item .MuiInputBase-root {
    background-color: var(--info-light-color);
}

.kanban-pipeline-item .MuiInputBase-root {
    padding: 5px 14px;
    background-color: #ffffff;
}

.kanban-pipeline-item .MuiInputBase-input {
    padding: 0;
}

.kanban-candidate-container {
    background-color: #ffffff;
    border: 1px solid var(--info-light-color);
    padding: 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 12px;
    height: 100%;
    overflow: auto;
}

.kanban-candidate-item {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #ECF4F4;
    box-shadow: 0 3px 4px 0 #00000040;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 10px
}

.kanban-candidate-item:hover {
    background-color: #EDEFF3 !important;
}

.kanban-pipeline-item .MuiTypography-root,
.kanban-candidate-item .MuiTypography-root {
    cursor: pointer;
}