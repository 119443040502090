#freelanceProfileForm .card {
    padding: 20px 15px !important;
    align-items: start !important;
}

#freelanceProfileForm .transparent {
    padding: 0 !important;
}

#freelanceProfileForm .rating-selector-star {
    flex-direction: column;
}

#freelanceProfileForm .rating-selector-star .MuiGrid-item {
    max-width: 100% !important;
}

#freelanceProfileForm .freelance-availability-display {
    font-size: 1rem;
    flex-direction: row !important;
    gap: 3px;
    color: var(--primary-light-color);
}

#freelanceProfileForm .freelance-availability-date {
    text-transform: lowercase;
}

#freelanceProfileForm .card .file-upload {
    padding: 10px !important;
}

#freelanceProfileForm .card .file-upload-container .MuiTypography-root {
    font-size: 12px !important;
    text-align: center;
}

#freelanceProfileForm .card .file-upload-container .uploaded-file {
    margin-bottom: 10px !important;
}

.achievement-item, .certification-item, .formation-item {
    position: relative;
    text-align: left;
    border: 1px solid #09174736;
    border-radius: 1ch;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 12px;
}

.text-theme {
    color: var(--info-main-color);
}

.text-title {
    text-align: left !important;
    font-weight: 700 !important;
    white-space: pre-line;
    font-family: "Poppins-bold", sans-serif;
}

.box-skills {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.container-skill {
    display: flex;
}

.child-skill {
    padding: 10px;
}

.child-40 {
    font-weight: 600;
    text-align: left;
    flex: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.child-60 {
    flex: 80%;
}

.container-child {
    display: grid;
    grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Deux colonnes avec largeur minimale de 200px */
    gap: 10px; /* Espacement entre les div enfants */
}

.child-item {
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    background-color: #00b3b320;
    padding: 4px;
    font-size: var(--font-size-base);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-skill {
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: scroll;
}

.text-skill {
    overflow: scroll;
    height: 100%;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.item-skill {
    border-radius: 10px;
    background-color: #00b3b320;
    border: 1px solid #ccc;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
}

.item-name-skill {
    flex: 1;
    font-size: 12px;
}

.delete-button-skill {
    background-color: transparent;
    color: red;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}