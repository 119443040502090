.recruitment-criteria-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 10px;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    background-color: white;
    box-sizing: border-box;
    overflow-y: auto;
    gap: 10px;
}

.recruitment-criteria-image {
    max-width: 300px;
    max-height: 280px;
}

.recruitment-criteria-image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.recruitment-question-item {
    display: flex;
    align-items: start;
    border: 1px dashed var(--light-light-color);
    padding: 10px;
    width: 100%;
    border-radius: 12px;
}

.recruitment-pipeline-item, .offer-answer-item {
    display: flex;
    align-items: start;
    border: 1px dashed var(--light-light-color);
    padding: 10px;
    width: 100%;
    border-radius: 12px;
    background-color: var(--info-light-color);
}

.offer-answer-item {
    border: none;
}

.recruitment-question-item .MuiOutlinedInput-notchedOutline,
.recruitment-pipeline-item .MuiOutlinedInput-notchedOutline {
    border: none;
    background-color: transparent;
}

.recruitment-pipeline-item .MuiInputBase-root {
    background-color: var(--info-light-color);
}

.recruitment-question-item .MuiInputBase-root,
.recruitment-pipeline-item .MuiInputBase-root {
    padding: 5px 14px;
}

.recruitment-pipeline-item.edit,
.recruitment-pipeline-item.edit .MuiInputBase-root {
    background-color: #ffffff;
}

.recruitment-pipeline-item .MuiInputBase-input {
    padding: 0;
}

.recruitment-question-item.error, .recruitment-pipeline-item.error {
    border-color: #d32f2f;
}