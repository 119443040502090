.freelance-availability-display {
    color: var(--primary-main-color);
}

.availability-status-available {
    color: var(--success-main-color);
}

.availability-status-partially-available {
    color: var(--warning-main-color);
}

.availability-status-not-available {
    color: var(--danger-main-color);
}

.freelance-availability-date {
    white-space: nowrap;
}