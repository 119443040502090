.user-card {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.user-card-image {
    width: 40px !important;
    height: 40px !important;
    object-fit: cover;
    border-radius: 50% !important;
}
