#addPortfolioBtn {
    border: 1px dashed var(--light-light-color);
    padding: 15px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    color: var(--primary-main-color) !important;
}

#addPortfolioBtn:hover {
    border-color: var(--primary-main-color);
}

#addPortfolioBtn .MuiButton-root {
    background-color: rgba(48, 55, 115, 0.04) !important;
    min-width: auto;
}

.portfolio-item {
    position: relative;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #09174736;
    border-radius: 1ch;
    display: flex;
    flex-direction: row;
    padding: 15px !important;
    margin-bottom: 15px;
}

.portfolios .portfolio-item:last-child {
    margin-bottom: 0 !important;
}

.display-portfolio-preview {
    width: 100%;
    border: 2px solid #ffffff;
}

.display-portfolio-item .file-image,
.display-portfolio-preview .file-image {
    width: 100% !important;
    border-radius: 5px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.display-portfolio-item .file-image,
.display-portfolio-item .MuiSkeleton-root {
    height: 100px !important;
    object-fit: cover !important;
}

.display-portfolio-preview .file-image,
.display-portfolio-preview .MuiSkeleton-root {
    height: 450px !important;
}

.display-portfolio-item {
    border: 2px solid #ffffff;
    border-radius: 7px;
}

.display-portfolio-item:hover {
    border-color: #ECF4F4;
}

.display-portfolio-item.active {
    border-color: var(--secondary-main-color) !important;
}

#freelancePortfolioDetail .display-portfolio-item .file-image,
#freelancePortfolioDetail .display-portfolio-item .file-image {
    height: 170px !important;
}