.common-board-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
}

.evaluation-board-scrollablelist,
.satisfaction-board-scrollablelist {
    overflow: hidden scroll;
    height: 100%;
    width: 100%;
    padding-right: 5px;
}

.evaluation-board-scrollablelistcontent,
.satisfaction-board-scrollablelistcontent {
    overflow: hidden;
}

.common-board-pdfviewerbox {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

#missionform .common-board-container {
    gap: 2vh;
}


/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    .common-board-container {
        width: 250%;
        padding-bottom: 10px;
        gap: 2vh;
    }

    .box-item .tag-quote,
    .box-item .tag-contract,
    .box-item .tag-invoice {
        max-width: 10rem;
        height: 28px;
    }
}