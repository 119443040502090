.pageheader {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    min-height: 50px;
}

.pageheader h1 {
    margin: 0;
}

.header-data-row-count {
    height: 100%;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    padding: 5px 0;
    background-color: var(--info-main-color);
    min-width: 50px;
    color: white;
    border-radius: 15px;
}

.pagetitle {
    font-size: 28px;
    font-family: "Poppins-bold", sans-serif;
    color: var(--primary-main-color);
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.form-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin: 4vh 0 0 0;
    box-sizing: border-box;
    gap: 2%;
}

.table-layout, .content-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
    gap: 3%;
}

.content-full-height .table-layout {
    padding-top: 6px;
    flex-grow: 1;
    overflow: hidden;
}

.workflowmenu {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    align-items: center;
    width: 302px !important;
    min-width: 302px !important;
    gap: 20px;
}


.standardform-marginauto {
    margin: auto;
}

.content::-webkit-scrollbar {
    width: 6px; /* Largeur de la scrollbar */
}

.content::-webkit-scrollbar-thumb {
    background-color: #e1e3ea; /* Couleur de la thumb (la poignée) */
    border-radius: 6px; /* Arrondir les coins de la thumb */
}

.content::-webkit-scrollbar-thumb:hover {
    background-color: #e1e3ea; /* Couleur de la thumb au survol */
}

.content::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Couleur de la piste */
}

.fsize-25 {
    font-size: 25px;
}

#selectedItemFooter {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid var(--light-light-color);
    border-radius: 10px;
    max-width: 50rem;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    z-index: 1000;
}


/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    .pageheader {
        flex-direction: column;
        min-height: auto !important;
    }

    .pagetitle {
        justify-content: start;
        font-size: 1.6em;
        min-height: auto;
    }

    .pageaction {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-top: 15px;
        gap: 10px;
    }

    .header-data-row-count {
        font-size: var(--font-size-base);
        padding: 5px 15px;
    }

    .display-bar {
        margin: 3vh 0;
    }

    .form-layout {
        flex-direction: column;
        margin: 3vh 0 0 0;
        gap: 10px;
    }

    .workflowmenu {
        width: 100% !important;
    }
}