.company-freelance-note {
    flex: 1 1;
    height: 100%;
}

.company-freelance-note .card {
    align-items: start !important;
    border: none !important;
}

.company-freelance-note-tab .MuiButton-root {
    padding: 12px 12px;
    font-size: 12px;
    border-radius: 10px;
    color: var(--info-main-color) !important;
}

.company-freelance-note-tab .MuiButton-containedLight {
    background-color: #D9D9D9 !important;
    color: var(--info-main-color) !important;
}

.interaction-item {
    position: relative;
    text-align: left;
    background-color: #ffffff;
    color: var(--primary-main-color) !important;
    border-radius: 1ch;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 12px;
}

.interaction-item .MuiTypography-root, .interaction-item .MuiTypography-body1 {
    color: var(--primary-main-color) !important;
}

.interaction-item .interaction-user {
    color: var(--info-main-color) !important;
    font-weight: bold !important;
}