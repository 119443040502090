@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animation d'apparition */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Animation de disparition */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Appliquez ces classes aux composants que vous souhaitez animer */
.fade-enter {
  animation: fadeIn 0.7s ease-in-out;
}

.fade-exit {
  animation: fadeOut 0.7s ease-in-out;
}

.spinner {
  animation: spin 1s linear infinite;
}

.message-component {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  padding: 3px 16px;
  box-sizing: border-box;
  margin-top: 5px;
}

.message-icon {
  /*padding: 10px 0px;*/
}

.message-text {
  padding: 0px 0px;
  margin: 0px 0px;
  flex: 1;
  text-align: center;
}

.message-information {
  background-color: #f2f2f215;
}

.message-loading {
  background-color: #09174715;
}

.message-error {
  background-color: #f66f8115;
}

.message-success {
  background-color: #00b3b315;
}
