/* appbar.css */

.app-bar-container {
    width: 15%;
    max-width: 350px; /* Largeur de la barre latérale */
    min-width: 245px;
    height: 100vh; /* Prend toute la hauteur de la page */
    box-sizing: border-box;
    position: fixed; /* Reste en place même en cas de défilement */
    background-color: #ffffff; /* Couleur de fond de la barre latérale */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2% 0 0 0;
    gap: 10vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.app-bar-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
}

.app-bar-menu p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    box-sizing: border-box;
    padding: 5% 5% 5% 0;
    cursor: pointer;
    border-radius: 5px;
    font-size: 13px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; /* Transition fluide */
}

.active-navigation-link {
    font-family: "Poppins-bold", sans-serif !important;
    background-color: #09174720;
    padding: 8px;
}

.navigation-link {
    align-items: center;
    cursor: pointer;
    border-radius: 10px !important;
    transition: background-color 0.3s ease;
    color: var(--primary-main-color);
}

.appbar-menu-category {
    flex: 1;
    text-align: left;
}

.bloc-nav span {
    margin-left: 15px;
}

.app-bar-menu p:hover {
    background-color: #09174750; /* Fond de couleur bleue au survol */
}

.bloc-nav li .navigation-link {
    padding: 8px;
    border-radius: 4px;
}

.bloc-nav li .navigation-link:hover {
    background-color: #09174750;
}

.main-bloc {
    padding-right: 32px;
}

.app-bar-logo {
    max-width: 160px;
    height: 80px;
    width: 100%;
    cursor: pointer;
}

.app-bar-logo .file-image,
.app-bar-logo .file-image {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 0 !important;
}

.app-bar-account {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #09174720;
    border-top: #09174730 solid 1px;
    padding: 8px 0;
}

.menu-tag {
    background-color: grey;
    color: white;
    font-size: 10px;
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 5px;
}

.menu {
    color: var(--primary-main-color);
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

nav ul {
    list-style: none;
    padding: 0;
}

nav ul li {
    padding: 5px 10px;
    text-align: left;
}

nav ul li a {
    text-decoration: none;
    color: #fff;
    display: block;
}

nav ul li a i {
    margin-right: 10px;
}

.submenu-bloc-nav {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px
}

.submenu-bloc-nav li {
    padding: 0 10px 0 28px;
}

main {
    overflow-y: auto;
}

.d-flex {
    display: flex;
}

.menu::-webkit-scrollbar {
    width: 6px; /* Largeur de la scrollbar */
}

.menu::-webkit-scrollbar-thumb {
    background-color: #e1e3ea; /* Couleur de la thumb (la poignée) */
    border-radius: 6px; /* Arrondir les coins de la thumb */
}

.menu::-webkit-scrollbar-thumb:hover {
    background-color: #e1e3ea; /* Couleur de la thumb au survol */
}

.menu::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Couleur de la piste */
}

.menu {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main-header {
    align-items: stretch;
    display: flex;
    height: 48px;
    justify-content: space-between;
    position: relative;
    transition: left 0.3s ease;
}

.sticky-header {
    background-color: #fff;
    padding: 8px;
    position: -webkit-sticky; /* Pour la compatibilité avec Safari/IOS */
    position: sticky;
    top: 0; /* Fixe en haut de la fenêtre */
    /*z-index: 10;  Définissez un z-index pour gérer la superposition */
}

.main-wrapper {
    display: flex;
    flex-direction: column;
}

.child-content:last-child {
    margin-top: auto;
}