.layout {
    display: grid;
    grid-template-columns: 200px 1fr; /* Colonne pour la barre de navigation, colonne flexible pour le contenu */
    grid-template-rows: 1fr 70px; /* Une ligne pour l'AppBar, une ligne flexible pour le contenu, et une ligne pour le footer */
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #09174707;
    grid-template-areas:
    "appbar content"
    "appbar footer";
    box-sizing: border-box;
}

.app-bar-container {
    grid-area: appbar; /* Place la barre de navigation dans la zone 'appbar' */
    overflow-y: auto;
}

.app-bar-container::-webkit-scrollbar {
    width: 6px;
}

.app-bar-container::-webkit-scrollbar-thumb {
    background-color: #e1e3ea; /* Couleur de la thumb (la poignée) */
    border-radius: 6px; /* Arrondir les coins de la thumb */
}

.app-bar-container::-webkit-scrollbar-thumb:hover {
    background-color: #e1e3ea; /* Couleur de la thumb au survol */
}

.app-bar-container::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Couleur de la piste */
}

.container-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: calc(100vw - 182px);
}

.content {
    grid-area: content; /* Place le contenu dans la zone 'content' */
    overflow-y: auto;
    padding: 2% 2% 3% 2%;
    margin: 2% 2% 1% 2%;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.content-full-height {
    padding-bottom: 0 !important;
    margin-bottom: 5px !important;
}

.footer {
    grid-area: footer; /* Place le footer dans la zone 'footer' */
}


/* Styles pour les écrans très petits (sm) */
/* Styles pour les écrans moyens (md) */
@media (max-width: 600px), (min-width: 601px) and (max-width: 1023px) {
    .layout {
        display: flex;
        flex-direction: column;
    }

    .container-content {
        width: 100%;
        height: 93vh;
    }

    .app-bar-logo-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .app-bar-logo {
        width: 140px;
        margin: 0;
    }

    .MuiPaper-root.MuiDrawer-paper {
        max-height: 100% !important;
        width: 250px;
    }

    .MuiDrawer-paper.MuiDrawer-paper .child-content:first-child {
        display: flex;
        justify-content: center;
        min-height: 44px;
    }

    .MuiDataGrid-footerContainer {
        justify-content: center !important;
    }
}
