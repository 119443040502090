.storybook-button {
    box-sizing: border-box;
    font-weight: bold;
    border: 0;
    border-radius: 20px;
    border-color: var(--primary-light-color);
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    text-align: center;
}

.storybook-button--primary {
    color: white;
    background-color: var(--primary-light-color);
}

.storybook-button--secondary {
    color: var(--primary-light-color);
    background-color: white;
    box-shadow: var(--primary-light-color) 0 0 0 1px inset;
}

.storybook-button--medium {
    font-size: 20px;
    padding: 1em 1em;
}

.storybook-button--large {
    font-size: 1vw;
    padding: 1em 1em;
    width: 100%;
}

.MuiButton-root {
    font-family: "Poppins-bold", sans-serif !important;
    text-transform: initial !important;
    box-sizing: border-box !important;
    box-shadow: none !important;
    text-align: center !important;
    font-weight: bold !important;
    line-height: 1 !important;
}

.MuiButton-root.MuiButton-containedLight.Mui-disabled {
    color: #ffffff !important;
    background-color: var(--light-main-color) !important;
}

.MuiButton-root.MuiButton-square {
    border-radius: 4px !important;
    min-width: auto !important;
}

.MuiButton-startIcon {
    margin-right: 5px !important;
}

.copy-link-button .MuiButton-startIcon {
    margin-right: 8px !important;
}

.MuiButton-root.MuiButton-containedSizeLarge.MuiButton-containedSecondary:hover,
.MuiButton-root.MuiButton-containedSizeMedium.MuiButton-containedSecondary:hover,
.MuiButton-root.MuiButton-containedSizeSmall.MuiButton-containedSecondary:hover {
    background-color: #f95d72;
}

.MuiButtonBase-root.MuiButton-root:hover {
    box-shadow: none;
}

.MuiButton-root.MuiButton-outlined {
    background-color: #ffffff;
}

/* Large */
.MuiButton-root.MuiButton-containedSizeLarge,
.MuiButton-root.MuiButton-outlinedSizeLarge,
.MuiButton-root.MuiButton-textSizeLarge {
    padding: 16px 21px;
    font-size: 18px;
    border-radius: 20px;
}

.MuiButton-iconSizeLarge > *:nth-of-type(1) {
    font-size: 18px !important;
    transform: scale(1.4);
}

/* Medium */
.MuiButton-root.MuiButton-containedSizeMedium,
.MuiButton-root.MuiButton-outlinedSizeMedium {
    padding: 16px 19px;
    font-size: 16px;
    border-radius: 20px;
    min-width: 10rem;
}

.MuiButton-iconSizeMedium > *:nth-of-type(1) {
    font-size: 16px !important;
    transform: scale(1.4);
}


/* Small */
.MuiButton-root.MuiButton-containedSizeSmall,
.MuiButton-root.MuiButton-outlinedSizeSmall {
    padding: 12px 16px;
    font-size: var(--font-size-base);
    border-radius: 15px;
}

.MuiButton-root.MuiButton-outlinedSizeSmall {
    padding: 11px 15px;
}

.MuiButton-iconSizeSmall > *:nth-of-type(1) {
    font-size: 12px !important;
    transform: scale(1.4);
}

/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    .MuiButton-root.MuiButton-containedSizeLarge,
    .MuiButton-root.MuiButton-outlinedSizeLarge,
    .MuiButton-root.MuiButton-textSizeLarge {
        padding: 16px 1.2em;
        font-size: 16px;
    }

    .MuiButton-iconSizeLarge > *:nth-of-type(1) {
        transform: scale(1.4);
    }

    /* Medium */
    .MuiButton-root.MuiButton-containedSizeMedium,
    .MuiButton-root.MuiButton-outlinedSizeMedium {
        padding: 12px 1.2em;
        font-size: var(--font-size-base);
        min-width: 8rem;
    }

    .MuiButton-iconSizeMedium > *:nth-of-type(1) {
        transform: scale(1.4);
    }

    /* Small */
    .MuiButton-root.MuiButton-containedSizeSmall,
    .MuiButton-root.MuiButton-outlinedSizeSmall {
        padding: 12px 13px;
        font-size: 11px;
    }

    .MuiButton-root.MuiButton-outlinedSizeSmall {
        padding: 11px 12px;
    }
}