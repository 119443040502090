.field {
    margin: 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.field h1 {
    text-align: center;
    font-size: 2em;
    margin: 0;
}

.field p {
    margin: 0;
}

.centerformcard {
    padding: 30px 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    width: 450px;
}

p {
    font-size: var(--font-size-base);
    font-weight: 500;
    text-align: left;
    cursor: default;
    margin-bottom: 3px;
}

.indexlink {
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    color: var(--primary-light-color) !important;
    font-size: var(--font-size-base);
}

.error-message {
    color: red;
    text-align: center;
    width: 100%;
}

.inputindex {
    box-sizing: border-box;
    width: 100%;
}

.inputform {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.buttonbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.buttonbox .MuiButton-root {
    background-color: var(--primary-light-color) !important;
}

/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    p {
        font-size: 12px;
    }
}