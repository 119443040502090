.MuiStepIcon-root {
    width: 32px !important;
    height: 32px !important;
}

.MuiStepIcon-root.Mui-active, .MuiStepIcon-root.Mui-completed {
    color: var(--info-main-color) !important;
}


.MuiStepLabel-label {
    margin-top: 10px !important;
}

.MuiStepLabel-label.Mui-active, .MuiStepLabel-label.Mui-completed {
    color: var(--info-main-color) !important;
}

.MuiStepConnector-root {
    top: 15px !important;
}


/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    .MuiStepIcon-root {
        width: 25px !important;
        height: 25px !important;
    }

    .MuiStepper-root .MuiStepLabel-label  {
        font-size: 0.8rem;
    }

    .MuiStepConnector-root {
        top: -2px !important;
    }
}