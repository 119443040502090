.table-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
}

.table-content {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 1%;
    margin: 0;
    padding: 0;
}

.MuiDataGrid-root {
    border: none !important;
    color: #091747 !important;
    font-size: var(--font-size-base) !important;
}

.MuiDataGrid-topContainer::after {
    background-color: transparent !important;
}

.MuiDataGrid-filler > div {
    border: none !important;
}

.MuiDataGrid-columnHeaders {
    border: none !important;
    border-radius: 20px !important;
}

.MuiDataGrid-columnHeaders > div[role=row] {
    background-color: var(--info-light-color) !important;
    border-radius: 20px !important;
}

.MuiDataGrid-columnHeader {
    background-color: var(--info-light-color);
    border: none !important;
    padding: 0 20px !important;
}

.MuiDataGrid-columnHeader--pinnedLeft, .MuiDataGrid-columnHeader--pinnedRight {
    background-color: var(--info-light-color) !important;
}

.MuiDataGrid-columnHeader[aria-colindex="1"],
.MuiDataGrid-cell--pinnedLeft:first-child {
    border-radius: 20px 0 0 20px !important;
}

.MuiDataGrid-columnHeader--last,
.MuiDataGrid-cell--pinnedRight:nth-last-child(2) {
    border-radius: 0 20px 20px 0 !important;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--rounded {
    border-radius: 20px !important;
}

.MuiDataGrid-columnHeader--pinnedRight.MuiDataGrid-columnHeader--last {
    right: -2px !important;
}

.MuiDataGrid-columnHeaders .MuiDataGrid-iconSeparator {
    opacity: 0;
}

.MuiDataGrid-columnHeaders:hover .MuiDataGrid-iconSeparator {
    opacity: 1;
}

.MuiDataGrid-columnHeader--last .MuiDataGrid-iconSeparator {
    display: none;
}

.MuiDataGrid-filler {
    pointer-events: none;
}

.MuiDataGrid-filler--pinnedLeft, .MuiDataGrid-filler--pinnedRight {
    background-color: transparent !important;
}

.MuiDataGrid-scrollbarFiller--header {
    border: none !important;
}

.MuiDataGrid-scrollbarFiller--header.MuiDataGrid-scrollbarFiller--pinnedRight {
    background-color: transparent !important;
}

.MuiDataGrid-scrollbar--horizontal {
    left: 0;
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.MuiDataGrid-columnHeaderTitle {
    color: #091747 !important;
    white-space: normal !important;
    line-height: 1.6;
    text-align: center;
}

.MuiDataGrid-virtualScrollerRenderZone {
    padding: 5px 0 15px 0;
}

.MuiDataGrid-row {
    border-radius: 20px !important;
    box-shadow: rgba(100, 100, 111, 0.103) 0 1px 10px 0;
    background-color: #ffffff;
    height: 61px !important;
    max-height: 61px !important;
    cursor: pointer;
    /*transition: background-color 0.3s ease;*/
}

.MuiDataGrid-row .user-card {
    justify-content: start !important;
}

.MuiDataGrid-cell {
    display: flex !important;
    align-items: center;
    height: 61px !important;
    max-height: 61px !important;
    line-height: 1.43 !important;
    border: none !important;
    padding: 0 30px !important;
    white-space: normal !important;
}

.MuiDataGrid-cell--editing.MuiDataGrid-cell {
    z-index: 2 !important;
    padding: 10px !important;
    max-height: none !important;
    height: fit-content !important;
}

.MuiDataGrid-row.Mui-selected .MuiDataGrid-cell--pinnedLeft {
    background-color: #E6E7EC !important;
}

.MuiDataGrid-row:active, .MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-hovered,
.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft {
    background-color: #EDEFF3 !important;
}

.MuiDataGrid-cell .MuiDataGrid-cell--textLeft {
    align-items: start !important;
}

.MuiDataGrid-cellContent {
    overflow: hidden;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}

.MuiDataGrid-columnHeaderCheckbox {
    padding: 0 !important;
    cursor: pointer;
}

.MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root {
    padding: 0 !important;
    visibility: hidden;
}

.MuiDataGrid-cellCheckbox {
    padding: 0 10px !important;
}

.MuiDataGrid-cellCheckbox .MuiCheckbox-root {
    visibility: hidden;
}

.MuiDataGrid-columnHeaders:hover .MuiCheckbox-root,
.MuiCheckbox-root.Mui-checked,
.MuiDataGrid-row:hover .MuiDataGrid-cellCheckbox .MuiCheckbox-root,
.MuiDataGrid-row.Mui-selected .MuiDataGrid-cellCheckbox .MuiCheckbox-root {
    visibility: visible;
}

.MuiDataGrid-cell.text-right {
    justify-content: end !important;
}

.MuiDataGrid-cell.px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.MuiDataGrid-cell.p-0 {
    padding: 0 !important;
}

.MuiDataGrid-cell-padding-sm .MuiDataGrid-columnHeaderCheckbox,
.MuiDataGrid-cell-padding-sm .MuiDataGrid-cell {
    padding: 0 5px !important;
}

.MuiDataGrid-cell-padding-20 .MuiDataGrid-columnHeaderCheckbox,
.MuiDataGrid-cell-padding-20 .MuiDataGrid-cell {
    padding: 0 20px !important;
}

.MuiDataGrid-overlay {
    background-color: transparent !important;
}

#searchContainer {
    width: 28vw;
}

.MuiDataGrid-panel .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 8px 14px !important;
    padding-left: 0 !important;
}

.MuiDataGrid-columnsManagementRow .Mui-disabled {
    display: none !important;
}

.MuiDataGrid-filterForm .MuiDataGrid-filterFormValueInput .MuiFormControl-root {
    width: 100% !important;
}

.MuiDataGrid-menuList .MuiTypography-root {
    color: inherit !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-input,
.MuiTablePagination-displayedRows {
    color: var(--primary-main-color) !important;
    font-size: var(--font-size-base) !important;
}

.MuiDataGrid-selectedRowCount {
    visibility: hidden !important;
}

.MuiCircularProgress-root {
    color: var(--info-main-color) !important;
}

.MuiDataGrid-pinnedColumnHeaders {
    z-index: 1;
}

.MuiDataGrid-columnHeader--checkbox {
    position: sticky;
    left: 0;
    z-index: 2;
}

/* Styles pour les écrans très petits (sm) */
@media (max-width: 600px) {
    #searchContainer {
        width: 100% !important;
    }
}